import React from "react";
import "./Join.css";
import emailjs from '@emailjs/browser';
import { useRef } from 'react';


const Join = () => {
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_zyn99gc', 'template_39vt5qi', form.current, 'tugmeuPrg5ip8Qu47')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
  return (
    <div className="join" id="join-us">
      <div className="left-j">
        <hr />
        <div>
        <span className="stroke-text ">Ready to</span>
        <span>Level up</span>
        </div>
        <div>
        <span >your body</span>
        <span className="stroke-text ">with us</span>
        </div>
      </div>
      <div className="right-j">
        <form ref={form} onSubmit={sendEmail} className="email-container">
            <input type="email" name="user-email" placeholder="Enter youre email to join us " />
        <button className="btn btn-j">Join now</button>
        </form>
      </div>
    </div>
  );
};

export default Join;
