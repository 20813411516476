import React from "react";
import "./Hero.css";
import Header from "../Header/Header";
import hero_image from "../../assets/hero_image.png";
import hero_image_back from "../../assets/hero_image_back.png";
import calories from "../../assets/calories.png";
import heart from "../../assets/heart.png";
import CountUp from "react-countup";
import { motion } from "framer-motion";

const Hero = () => {
  const transition= {type:'spring', duration : 4};
  const mobile = window.innerWidth <=768 ? true : false ; 
  return (
    <div className="hero">
      <div className="blur hero-blur"></div>
      <div className="left-h">
        <Header />
        {/*best ad */}
        <div className="the-best-ad">
          <motion.div
          initial={{left:mobile ? "165px ":  '238px'}}
          whileInView={{left:'8px'}}
          transition={{...transition,type:'tween'}}
          >
            
          </motion.div>

          <span>The best fitness club in town </span>
        </div>
        {/*Tittle Heanding*/}
        <div className="hero-text">
          <div>
            <span className="stroke-text">Shape </span>
            <span>Youre</span>
          </div>
          <div>
            <span>Ideal body</span>
          </div>
          <div>
            <span>
              In here we will help you to shape and build your ideal body and
              live up your life to fullest
            </span>
          </div>
        </div>
        {/*figures*/}
        <div className="figures">
          <div>
            <span>
              {" "}
              +<CountUp start={100} end={140} duration={4} />{" "}
            </span>
            <span>Expert Coaches</span>
          </div>
          <div>
            <span>
              +<CountUp start={750} end={978} duration={4} />{" "}
            </span>
            <span>MEMBERS JOINED</span>
          </div>
          <div>
            <span>
              +<CountUp start={20} end={50} duration={4} />{" "}
            </span>
            <span>FITNESS PROGRAMS</span>
          </div>
        </div>
        {/*hero buttons*/}
        <div className="hero-buttons">
          <button className="btn">Get started</button>
          <button className="btn">Learn more</button>
        </div>
      </div>
      {/*Right Side*/}
      <div className="right-h">
        <button className="btn">Joins us </button>
        <motion.div
          initial={{ right:"-1rem"}}
          whileInView={{right:"4rem"}}
          transition={transition}
           
          className="heart-rate"
        >
          <img src={heart} alt="" />
          <span>Heart rate </span>
          <span> 116 BPM</span>
        </motion.div>
        {/*Hero images*/}
        <img src={hero_image} alt="" className="hero_image" />
        <motion.img
        initial={{ right:'11rem'}}
        whileInView={{right:'20rem'}}
        transition={transition}
          src={hero_image_back}
          alt=""
          className="hero_image_back"
        />
        {/*Calories */}
        <motion.div
         initial={{ right:"37rem"}}
         whileInView={{right:"28rem"}}
         transition={transition}
          className="calories"
        >
          <img src={calories} alt="" />
          <div>
            <span>Calories burned</span>
            <span>220kcal</span>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;
