import './App.css';
import Footer from './component/Footer/Footer';
import Hero from './component/Hero/Hero';
import Join from './component/Join/Join';
import Plan from './component/Plan/Plan';
import Programs from './component/Programs/Programs';
import { Reasons } from './component/Reasons/Reasons';
import Testimonials from './component/Testimonials/Testimonials';
function App() {
  return (
    <div className="App">
          <Hero/>
          <Programs/>
          <Reasons/>
          <Plan/>
          <Testimonials/>
          <Join/>
          <Footer/>
    </div>
  );
}

export default App;
